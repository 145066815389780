import React, { useState } from "react";
export const Phases = (props) => {
  const { general, phases } = props;
  const [selected, setSelected] = useState(1);

  const CARD_TO_HIGHLIGHT = 1;

  const nextValue = () => {
    if (selected < phases.length - 1) {
      setSelected(selected + 1);
    }
  };

  const previousValue = () => {
    if (selected > 0) {
      setSelected(selected - 1);
    }
  };

  const CurrentPhase = () => {
    return (
      <div id="current-phase" className="center-row">
        <i className="fa fa-map-marker center-space-evenly-row">
        </i>
      </div>
    );
  };

  return (
    <header id="phases">
      <div className="intro">
        <div className="overlay">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-8 col-md intro-text">
                <h1>{general ? general.title : "Loading"}</h1>
              </div>
            </div>
            <div id="carrousel-standar" className="col-lg-12 carrousel-standar">
              {phases
                ? phases.map((d, i) => (
                    <div
                      className={`center-column ${
                        CARD_TO_HIGHLIGHT === i ? "selected" : "not-selected"
                      }`}
                    >
                      <div
                        key={`${d.title}-${i}`}
                        className={`text-center card-item `}
                        onClick={() => setSelected(i)}
                      >
                        {" "}
                        <i className={d.icon}></i>
                        <h3>{d.title}</h3>
                        {phases[i].text.map((line, index) => (
                          <p key={index}>{line}</p>
                        ))}
                      </div>
                      <div style={{ width: "100%" }}>
                        {CARD_TO_HIGHLIGHT === i && <CurrentPhase />}
                        <p className="period">{phases[i].period}</p>
                      </div>
                    </div>
                  ))
                : "Loading..."}
            </div>
            <div id="carrousel-mobile" className="container">
              <div className="flex-mobile">
                <div
                  className="p-2 next-previus-value center-row"
                  onClick={() => previousValue()}
                >
                  <img src="../img/previous-arrow.svg" alt="previous-arrow" />
                </div>
                <div className="p-8">
                  {phases?.length ? (
                    <div
                      key={`${phases[selected].title}-${selected}`}
                      className={`text-center card-item ${
                        CARD_TO_HIGHLIGHT === selected && "selected"
                      }`}
                    >
                      {" "}
                      <i className={phases[selected].icon}></i>
                      <h3>{phases[selected].title}</h3>
                      {phases[selected].text.map((line, index) => (
                        <p key={index}>{line}</p>
                      ))}
                      <p>{phases[selected].period}</p>
                    </div>
                  ) : (
                    "Loading..."
                  )}
                </div>
                <div
                  className="p-2 next-previus-value center-row"
                  onClick={() => nextValue()}
                >
                  <img src="../img/next-arrow.svg" alt="next-arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
