import React, { useState, useEffect } from "react";
import { Phases } from "./components/Phases";
import { Team } from "./components/Team";
import { Demo } from "./components/Demo";
import { Presentation } from "./components/Presentation";
import { Contact } from "./components/Contact";
import { Navigation } from "./components/Navigation";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Presentation data={landingPageData.Services} />
      <Phases
        general={landingPageData.Header}
        phases={landingPageData.Features}
      />
      <Team data={landingPageData.About} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
