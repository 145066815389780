import React, { useState } from "react";

export const Presentation = () => {
  const [selected, setSelected] = useState(0);

  const problemSlideContent = [
    {
      title: null,
      description:
        "7/10 low-income Americans reported at least one civil legal problem that significantly impacted their lives in the past year. That's ~42.6 million people.",
      icon: "writing-icon",
    },
    {
      title: null,
      description:
        "86% of the civil legal problems reported by low-income Americans in the past year received inadequate or no legal help, or ~36.6 million claims.",
      icon: "mail-in-icon",
    },
    {
      title: null,
      description:
        "Many of these Americans can't afford to file with a lawyer or pay the retainer required for even one-off legal questions",
      icon: "book-open-icon",
    },
    {
      title: null,
      description:
        "Others don't know how to navigate the complex legal world full of jargon, non-intuitive forms, and specific information",
      icon: "web-icon",
    },
  ];

  const CardPresentation = ({ title, text, icon }) => {
    return (
      <div id="cardPresentation">
        <div className="display-center">
          <img src={`../img/${icon}.png`} alt={`${icon}`} />
        </div>
        {title && <p className="title">{title}</p>}
        <p className="content">{text}</p>
      </div>
    );
  };

  const ProblemSlide = () => {
    const [itemSelected, setItemSelected] = useState(0);
    const nextItem = () => {
      if (itemSelected < problemSlideContent.length - 1) {
        setItemSelected(itemSelected + 1);
      }
    };

    const previousItem = () => {
      if (itemSelected > 0) {
        setItemSelected(itemSelected - 1);
      }
    };

    return (
      <div className="slide-container">
        <div className="problem-slide">
          <div className="left-side leftSideContainer">
            <div className="center-evenly">
              <div className="leftSideContainerTop">
                <h1 className="presentation-title">The Problem:</h1>
                <h2 className="text-americans">
                  Most Americans can't afford to fight a case.
                </h2>
              </div>
              <img
                className="puzzle-guys standar"
                src="../img/problem-guy.png"
                alt="puzzle-guys.png"
              />
            </div>
            <div id="carrousel-problem-standard">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "3.5rem",
                  margin: "0 auto",
                }}
                className="presentation-cards"
              >
                {problemSlideContent.map((content, index) => {
                  return (
                    <CardPresentation
                      key={index}
                      text={content.description}
                      icon={content.icon}
                    />
                  );
                })}
              </div>
            </div>
            <div id="carrousel-problem-mobile">
              <div className="center-row" style={{ gap: "2rem" }}>
                <img
                  className="puzzle-guys"
                  src="../img/problem-guy.png"
                  alt="puzzle-guys.png"
                />
                {selected === 0 ? (
                  <img
                    style={{
                      position: "absolute",
                      right: "7.5%",
                      top: "47.5%",
                    }}
                    onClick={() => nextValue()}
                    className="arrow-icon"
                    src="../img/arrow-right.png"
                    alt="next-arrow"
                  />
                ) : (
                  <div className="button-prev-next"></div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "3.5rem",
                  margin: "0 auto",
                }}
                className="presentation-cards"
              >
                <div
                  className="p-2 next-previus-value-ps center-row"
                  onClick={() => previousItem()}
                >
                  <img src="../img/previous-arrow.svg" alt="previous-arrow" />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "3.5rem",
                  }}
                >
                  {problemSlideContent.map((content, index) => {
                    return (
                      <CardPresentation
                        key={index}
                        text={content.description}
                        icon={content.icon}
                      />
                    );
                  })}
                </div>
                <div
                  className="p-2 next-previus-value-ps center-row"
                  onClick={() => nextItem()}
                >
                  <img src="../img/next-arrow.svg" alt="next-arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const solutionSlideContent = [
    {
      title: "Legal Information",
      description:
        "We provide users with clear answers to their questions via Al chatbot.",
      icon: "writing-icon",
    },
    {
      title: "Document Preparation",
      description:
        "We help them identify and fill out relevant documents to start their case.",
      icon: "mail-in-icon",
    },
    {
      title: "Automated Filing",
      description:
        "We file those documents on their behalf and Caze reacts to user case developments as they arise.",
      icon: "book-open-icon",
    },
  ];

  const SolutionSlide = () => {
    return (
      <div className="slide-container">
        <div className="problem-slide">
          <div className="left-side leftSideContainer">
            <div className="center-evenly">
              <div className="leftSideContainerTop">
                <h1 className="presentation-title-2">
                  Caze puts a lawyer on every smartphone
                </h1>
                <div
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p className="prentation-description-2">
                    Our long-term vision sees Caze as the one-stop-shop for
                    legal information supplemented by a-la-carte services such
                    as document preparation, filing help, and evidence gathering
                    all executed on desktop or a mobile app.
                  </p>
                </div>
              </div>
              <img
                className="puzzle-guys standar"
                src="../img/solution-guys.png"
                alt="puzzle-guys.png"
              />
            </div>
            <div id="carrousel-problem-standard">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "3.5rem",
                  margin: "0 auto",
                }}
                className="presentation-cards"
              >
                {solutionSlideContent.map((content, index) => {
                  return (
                    <CardPresentation
                      key={index}
                      title={content.title}
                      text={content.description}
                      icon={content.icon}
                    />
                  );
                })}
              </div>
            </div>
            <div id="carrousel-problem-mobile">
              <div className="center-row" style={{ gap: "2rem" }}>
                {selected === 1 ? (
                  <img
                    onClick={() => previousValue()}
                    className="arrow-icon"
                    src="../img/arrow-left.png"
                    alt="next-arrow"
                    style={{
                      position: "absolute",
                      left: "7.5%",
                      top: "47.5%",
                    }}
                  />
                ) : (
                  <div className="button-prev-next"></div>
                )}
                <img
                  className="puzzle-guys"
                  src="../img/solution-guys.png"
                  alt="puzzle-guys.png"
                />
                <div className="button-prev-next"></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignContent: "center",
                  gap: "3.5rem",
                  margin: "0 auto",
                }}
                className="presentation-cards"
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignContent: "center",
                    gap: "3.5rem",
                    margin: "0 auto",
                  }}
                >
                  {solutionSlideContent.map((content, index) => {
                    return (
                      <CardPresentation
                        key={index}
                        title={content.title}
                        text={content.description}
                        icon={content.icon}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const PRESENTATIONS = [<ProblemSlide />, <SolutionSlide />];

  const nextValue = () => {
    if (selected < PRESENTATIONS.length - 1) {
      setSelected(selected + 1);
    }
  };

  const previousValue = () => {
    if (selected > 0) {
      setSelected(selected - 1);
    }
  };
  return (
    <div id="presentation" className="text-center">
      <div className="flex-container">
        <div className="carrousel-header center-space-around-row">
          <div
            className="next-previus-value center-row"
            onClick={() => previousValue()}
          >
            {selected === 1 && (
              <img src="../img/previous-arrow.svg" alt="previous-arrow" />
            )}
          </div>
          <div className={`carrousel-content center-column`}>
            {PRESENTATIONS.length && PRESENTATIONS[selected]}
          </div>
          <div className="next-previus-value center-row">
            {selected === 0 && (
              <img
                onClick={() => nextValue()}
                src="../img/next-arrow.svg"
                alt="next-arrow"
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
