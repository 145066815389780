import React from "react";

const Modal = ({ show, onClose }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Your email has been successfully sent.</h2>
        <button
          onClick={onClose}
          type="submit"
          className="btn btn-custom btn-lg"
        >
          Got it
        </button>
      </div>
    </div>
  );
};

export default Modal;
