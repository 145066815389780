import React, { useState } from "react";

const Dialog = (props) => {
  const { image, name, description, email, experiences, isOpen, setIsOpen } =
    props;

  const shortDescription = description.slice(0, 150) + "... \n";

  return (
    <div className="card">
      <div className="image-container">
        <img className="card-img" src={image} alt={name} />
      </div>

      <div className="card-content">
        <h2 className="card-name">{name}</h2>
        <h3 className={`card-description ${isOpen ? "is-open" : null}`}>
          {isOpen ? description : shortDescription}
          {!isOpen && (
            <span
              onClick={() => setIsOpen(true)}
              className="card-content-description"
            >
              See more
            </span>
          )}
        </h3>
        <div className="icon-container">
          {experiences.map((experience) => (
            <img src={experience} className="experience-img" alt="Experience" />
          ))}
        </div>
        <div className="social-media-container">
          <div className="social-media-linkedin">
            <img
              src={"../img/linkedin.png"}
              className="social-media-img"
              alt="Linkedin"
            />

            <h4 className="card-bottom-name">{name}</h4>
          </div>

          <h4 className="card-bottom-name">{email}</h4>
        </div>
      </div>
    </div>
  );
};

export const Team = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div id="about">
      <div className="row overlay">
        <div className="row">
          <h2 className="text-demo">Team</h2>
          <div className="card-container">
            <Dialog
              image={"../img/face-1.png"}
              name={"T.J. Foley"}
              description={
                "T.J. led the Trust and Safety team at an early stage technology company, Patio. He has volunteered in the past for the Small Claims Advisory Service and has worked with an international NGO to integrate technology into the delivery of legal services. He graduated cum laude with high honors in Social Studies from Harvard College. Currently, T.J. acts as a Strategist at Gemic, advising multi-billion-dollar clients on AI and ML advancements. T.J. enjoys photography and playing with Adi’s two golden retrievers."
              }
              email={"TJ@Caze.Ai"}
              experiences={[
                "../img/harvard-logotype.png",
                "../img/patio-logotype.png",
                "../img/gemic-logotype.png",
              ]}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
            <Dialog
              image={"../img/face-2.png"}
              name={"Adi Kapoor"}
              description={
                "Adi worked as a Consultant at EY engaging Fortune 500 clients on financial performance improvement and M&A separation strategy. Before that, he supported two Venture Capital firms, ERA and Katapult, where he advised on cohort selection. He graduated with honors from Vanderbilt University with a B.A. in Strategy & Innovation. Adi has two Golden Retrievers and enjoys pretending he’s an iron chef on the weekends."
              }
              email={"Adi@Caze.Ai"}
              experiences={[
                "../img/ey-logotype.png",
                "../img/vanderbilt-university-logotype.png",
                "../img/two-lights-logotype.png",
              ]}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
